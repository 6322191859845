<!--
 * @Author: your name
 * @Date: 2020-10-20 11:56:37
 * @LastEditTime: 2020-10-20 16:07:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \GrandHyattc:\Users\25740\Desktop\jciweb\src\views\AuthPages\AccountRecoveryAfter.vue
-->
<template>
  <div class="AccountRecoveryAfter">
    <div class="titleBox d-flex align-items-center">
      <div class="imgBox" @click="$router.push({name: 'auth1.sign-in1' })">
        <img src="../../assets/images/login/chevron-left.png" alt="" />
      </div>
      <div class="title">Account recovery</div>
    </div>

    <div class="mt-4">
      <div class="contentBox">
        <img src="../../assets/images/login/success.png" alt="" />
        <div class="emailAddress">Email：{{this.$route.query.email}}</div>
        <div class="">Your application has been submitted.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignIn1',
  data: () => ({}),
  mounted () {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.AccountRecoveryAfter {
  .titleBox {
    margin-bottom: 83px;
    position: relative;
    .imgBox {
      position: absolute;
      left: -10px;
      cursor: pointer;
    }
    .title {
      font-size: 28px;
      padding-left: 20px;
      font-weight: 400;
      color: #3f414d;
    }
  }
  .contentBox {
    text-align: center;
    .emailAddress {
      margin: 62px 0 30px 0;
    }
  }
}
</style>
